import { createSelector } from 'reselect';
import Domain from './name';

const featureSelector = state => state[Domain];

export const selectedLocale = createSelector(
  featureSelector,
  state => state?.selectedLocale
);

export const activatedRouteId = createSelector(
  featureSelector,
  state => state.activatedRouteId
);

export const dynamicMessages = createSelector(
  featureSelector,
  state => state.dynamicMessages
);

export const mergedDynamicMessages = createSelector(dynamicMessages, state =>
  Object.values(state).reduce(
    (acc, messages) => ({
      ...acc,
      ...messages,
    }),
    {}
  )
);

export const dynamicMessagesByKey = key =>
  createSelector(dynamicMessages, state => state && state[key]);
